
.steps
  @apply w-full
  @screen lg
    width: calc(100% - 400px)
  &.full-width
    width: 100%
  &__step
    @apply flex flex-col text-center relative
    line-height: 22px

    @screen sm
      line-height: 27px
      &:not(:last-of-type)::before
        @apply absolute border-b-3 border-grey pin-l w-full pointer-events-none
        font-family: 'owl'
        content: ''
        z-index: 1
        top: 15px
        left: 30px
    &__step-status
      @apply flex items-center cursor-pointer
      @screen sm
        @apply flex-col
      label
        @apply border-3 mb-4 rounded-full text-grey relative cursor-pointer
        width: 32px
        height: 32px
        z-index: 2
        &.number
          @apply bg-white border-grey
        &.check
          @apply bg-green-dark border-green-dark text-white font-bold text-lg

      span
        @apply text-grey text-sm font-medium
        letter-spacing: 1px
        padding-left: 1px
        max-width: 80px

    &.done
      &::before
        @apply border-green-dark
      label
        &.number
          @apply hidden
        &.check
          @apply block

      span, i.owl-arrow-right
        @apply text-green-dark

    &.active
      label
        &.number
          @apply text-green-dark border-green-dark
      span
        @apply border-b-1 border-green-dark text-green-dark
        @screen sm
          @apply border-none
